import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="install-prompt"
export default class extends Controller {
  static targets = ["installButton"];

  connect() {
    console.log("stimulus de install prompt");
    if (navigator.serviceWorker) {
      console.log("[Fortis]", "Service worker registration started!");
      navigator.serviceWorker.register("/service-worker.js?v=1.4", { scope: "/" })
        .then(() => navigator.serviceWorker.ready)
        .then(() => console.log("[Fortis]", "Service worker registered!"))
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });

      window.addEventListener('beforeinstallprompt', this.showInstallModal.bind(this));
    }
  }

  showInstallModal(e) {
    e.preventDefault();
    this.deferredPrompt = e;
    const lastPromptTime = localStorage.getItem('last-install-prompt:ts');
    const viewCount = parseInt(localStorage.getItem('install-prompt-views')) || 0;
    const now = Date.now();

    let showPrompt = false;

    if (viewCount === 0) {
      showPrompt = true;
    } else {
      const timeSinceLastPrompt = now - parseInt(lastPromptTime);
      switch (viewCount) {
        case 1:
          showPrompt = timeSinceLastPrompt > 7 * 86400000; // 7 days
          break;
        case 2:
          showPrompt = timeSinceLastPrompt > 14 * 86400000; // 14 days
          break;
        case 3:
          showPrompt = timeSinceLastPrompt > 30 * 86400000; // 30 days
          break;
        default:
          showPrompt = false;
      }
    }

    if (showPrompt) {
      document.getElementById("aux-open-modal").click();
    }

    // $('#installModal').on('hidden.bs.modal', () => {
    //   localStorage.setItem('install-prompt-views', `${viewCount + 1}`);
    // });

    document.getElementById('confirmInstallButton').addEventListener('click', () => {
      // Ocultar el modal
      // $('#installModal').modal('hide');
      // Mostrar el popup de instalación
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          localStorage.removeItem('install-prompt-views');
        } else {
          localStorage.setItem('install-prompt-views', `${viewCount + 1}`);
        }
        this.deferredPrompt = null;
      });
    });
  }
}
