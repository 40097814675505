// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "infoWindow"]

  connect() {
    if (typeof (google) != "undefined") {
      this.initMap()
    }
  }
  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get('latitude') || -25.51281011333503, this.data.get('longitude') || -54.60639667323982),
      zoom: this.data.get('latitude') == null ? 11 : 17
    })
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_component', "geometry", "icon", "name"])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(this.data.get('latitude') || 0, this.data.get('longitude') || 29),
      draggable: true,
      visible: true,
      position: new google.maps.LatLng(this.data.get('latitude') || -25.51281011333503, this.data.get('longitude') || -54.60639667323982),
    })
    google.maps.event.addListener(this.marker, 'dragend', this.draggedMarker.bind(this))

    //GEOLOCATION
    this.infoWindow = new google.maps.InfoWindow();

    const locationButton = document.createElement("button");
    locationButton.type = "button"
    locationButton.textContent = "Mi Ubicación";
    locationButton.classList.add("custom-map-control-button");
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    locationButton.addEventListener("click", () => {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            this.infoWindow.setPosition(pos);
            this.infoWindow.open(this.map);
            this.map.setCenter(pos);
            this.marker.setPosition(pos);
          },
          () => {
            handleLocationError(true, this.infoWindow, this.map.getCenter());
          }
        );
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, this.infoWindow, this.map.getCenter());
      }
    });
  }
  placeChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert(`No details avaiblable for input:${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }

  draggedMarker() {
    let place = this.marker.getPosition()

    this.latitudeTarget.value = place.lat()
    this.longitudeTarget.value = place.lng()
  }
  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }
}
